.homeContainer {
  height: 100vh;
  width: 100%;
  margin: auto;
}

#banner {
  margin: 0 auto;
  height: 100%;
  // background-image: url("../../space.jpeg");
  padding: 10% 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.bannerLogo {
  position: absolute;
  width: 70%;
  margin: auto;
  top: calc(50% - 20px);
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.projectsHomepage {
  display: flex;
  flex-direction: row;
  height: 60vh;
  width: 100%;
  padding: 0px;
}

.projectDisplay {
  width: 100%;
  flex: 1;
}

.home-img-fluid {
  display: block;
  margin: auto;
  width: 80%;
}

.hpProject2 {
  margin-top: 200px;
}

.hpProject3 {
  margin-top: 400px;
}

#connectBanner {
  height: 35%;
  clip-path: polygon(0 0, 200% 70%, 0% 150%, 0 0%);
  background-image: url("../../space.jpeg");
  padding: 10% 0;
  text-align: center;
}

@media only screen and (min-wdith: 1000px) {
  .projectsHomepage {
    height: 100vh;
  }
}

@media only screen and (max-width: 700px) {
  .homeContainer {
    width: 100%;
  }
  
  #banner {
    margin: 0 auto;
    height: 35%;
    // background-image: url("../../space.jpeg");
    padding: 100% 0;
    text-align: center;
    position: relative;
    overflow: hidden;
  }

  .projectsHomepage {
    height: 100vh;
  }
}

.carouselImage {
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
}
