.servicesContainer {
  width: 80%;
  padding-top: 100px;
  padding-bottom: 50px;
  margin: auto;
}

h2,
h4 {
  text-align: center;
}

.btn {
  height: 50px;
  width: 200px;
  justify-content: center;
  transition: 0.8s;
}

.servicesBtn,
.contactBtn,
a {
  text-align: center;
  background: transparent;
  color: black;
}

.servicesBtn {
  margin-top: -30px;
  padding-bottom: 30px;
}

.btn-primary {
  background: -webkit-linear-gradient(0deg, transparent 50%, #000 50%);
  background-size: 400px 80px;
  border: 2px solid #000000;
}

.btn-primary:hover {
  a {
    color: white;
  }
  background-position: 200px 0;
  background-color: transparent;
  border-color: transparent;
}

@media only screen and (max-width: 700px) {
  .servicesContainer {
    width: 95%;
    padding-top: 10px;
  }
  .servicesBtn {
    margin-top: -40px;
    padding-bottom: 70px;
  }
}
