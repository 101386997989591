.h5 {
    height: auto;
}

@media only screen and (min-width: 768px) {
    .postsContainer {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .postsContainer {
        margin-bottom: 50px;
        margin-top: 10px;
    }
  }

.viewSiteBtn a {
    color: black;
}

.card-body, .card-footer {
    text-align: left;
}

.card {
    min-height: 400px;
    max-width: 400px;
}

.card-image {
    max-height: 250px;
    min-height: 250px;
}

.card-body {
    height:100px;
}

.card-footer {
    height:50px;
}

.disabled-link {
    pointer-events: none;
}
