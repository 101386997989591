.projectsContainer {
  width: 80%;
  padding-top: 100px;
  padding-bottom: 50px;
  margin: auto;
}

.projects {
  padding-top: 30px;
}

.bottomProject {
  padding-bottom: 100px;
}

.img-fluid {
  display: block;
  margin: auto;
  width: 45%;
}

.viewSiteBtn a {
  color: black;
}

.caseStudyBtn a {
  color: black;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.modal-header {
  height: 80px;
} 

@media only screen and (min-width: 992px) {
  .projects h2 {
    padding-top: 80px;
    display: inline-block;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 991px) {
  .projectsContainer {
    width: 95%;
  }

  .projects {
    text-align: center;
  }

  .projects h2 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .projectsContainer {
    padding-top: 10px;
  }
}