.contactDiv {
  display: flex;
  justify-content: center;
}

.f1 {
  text-align: center;
}

.hover-black:hover {
  background-color: #000 !important;
}

.checkbox {
  text-align: center;
}

.checkbox input {
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}

.checkbox label {
  margin: 0px 20px 0px 8px;
}

.submitBtn {
  background: transparent;
  border-color: white;
  color: white;
}

.submitBtn:hover {
  color: #fff;
  background: #000;
}

@media only screen and (max-width: 767px) {
  .mv6 {
    margin-top: 10px;
  }
}
.spinner {
  text-align: center;
  padding: 50px;;
}
.inquiry-submission-status {
  text-align: center;
  padding: 50px;
}

.submission-status-image {
  width: 80%;
}

.btn {
  height: 50px;
  width: 200px;
  justify-content: center;
  transition: 0.8s;
}

.errorBtn,
a {
  text-align: center;
  background: transparent;
  color: black;
}

.btn-primary {
  background: -webkit-linear-gradient(0deg, transparent 50%, #000 50%);
  background-size: 400px 80px;
  border: 2px solid #000000;
}

.btn-primary:hover {
  a {
    color: white;
  }
  background-position: 200px 0;
  background-color: transparent;
  border-color: transparent;
}
