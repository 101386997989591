@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 700px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
